let throttle = require('lodash/throttle');
let delegate = require('client/delegate');

class TutorialMap {
  constructor(elem) {
    this.elem = elem;

    this.throttleFilter = throttle(this.filter, 200);

    this.showTasksCheckbox = elem.querySelector('[data-tutorial-map-show-tasks]');
    this.showTasksCheckbox.checked = +sessionStorage.showTasksCheckbox;

    this.updateShowTasks();

    this.showTasksCheckbox.onchange = this.updateShowTasks.bind(this);

    this.filterInput = this.elem.querySelector('[data-tutorial-map-filter]');
    this.textInputBlock = this.elem.querySelector('.tutorial-map__filter .text-input');

    this.filterInput.oninput = this.onFilterInput.bind(this);
    this.filterInput.onkeydown = this.onFilterKeydown.bind(this);

    this.elem.querySelector('.text-input__clear').onclick = () => {
      this.filterInput.value = '';
      this.showClearButton(false);
      this.filter('');
    };

    this.chaptersCollapsed = JSON.parse(sessionStorage.tutorialMapChapters || "{}");
    this.showChaptersCollapsed();

    this.delegate('.tutorial-map__item > .tutorial-map__link', 'click', (event) => {
      event.preventDefault();
      let href = event.delegateTarget.getAttribute('href');
      if (this.chaptersCollapsed[href]) {
        delete this.chaptersCollapsed[href];
      } else {
        this.chaptersCollapsed[href] = 1;
      }
      sessionStorage.tutorialMapChapters = JSON.stringify(this.chaptersCollapsed);
      this.showChaptersCollapsed();
    });

    let activeLink = this.elem.querySelector('.tutorial-map-list-three__link[href="' + location.pathname + '"]');
    if (activeLink) {
      activeLink.classList.add('tutorial-map-list-three__link_active');
    }

    this.filterInput.focus();

  }

  showChaptersCollapsed() {
    let links = this.elem.querySelectorAll('.tutorial-map__item > .tutorial-map__link');
    for (let i = 0; i < links.length; i++) {
      let link = links[i];

      if (this.chaptersCollapsed[link.getAttribute('href')]) {
        link.parentNode.classList.add('tutorial-map__item_collapsed');
      } else {
        link.parentNode.classList.remove('tutorial-map__item_collapsed');
      }
    }
  };


  updateShowTasks() {
    if (this.showTasksCheckbox.checked) {
      this.elem.classList.add('tutorial-map_show-tasks');
    } else {
      this.elem.classList.remove('tutorial-map_show-tasks');
    }

    sessionStorage.showTasksCheckbox = this.showTasksCheckbox.checked ? "1" : "0";
  }

  onFilterInput(event) {
    this.showClearButton(event.target.value);
    this.throttleFilter(event.target.value);
  }

  onFilterKeydown(event) {
    if (event.keyCode === 27) { // escape
      this.filterInput.value = '';
      this.showClearButton(false);
      this.filter('');
    }
  }

  showClearButton(show) {
    if (show) {
      this.textInputBlock.classList.add('text-input_clear-button');
    } else {
      this.textInputBlock.classList.remove('text-input_clear-button');
    }
  }

  // focus on the map itself, to allow immediate scrolling with arrow up/down keys
  focus() {
    this.elem.tabIndex = -1;
    this.elem.focus();
  }

  filter(value) {
    value = value.toLowerCase();

    let showingTasks = this.showTasksCheckbox.checked;

    let links = this.elem.querySelectorAll('.tutorial-map-list a');

    let topItems = this.elem.querySelectorAll('.tutorial-map-list-two__item');

    function checkLiMatch(li) {
      return isSubSequence(li.querySelector('a').innerHTML.toLowerCase(), value.replace(/\s/g, ''));
    }

    // an item is shown if any of its children is shown OR it's link matches the filter
    for (let i = 0; i < topItems.length; i++) {
      let li = topItems[i];
      let subItems = li.querySelectorAll('.tutorial-map-list-three__item');

      let childMatch = Array.prototype.reduce.call(subItems, function (prevValue, subItem) {

        let childMatch = false;

        if (showingTasks) {
          let subItems = subItem.querySelectorAll('.tutorial-map-list-four__item');
          childMatch = Array.prototype.reduce.call(subItems, function (prevValue, subItem) {
            let match = checkLiMatch(subItem);
            subItem.hidden = !match;
            return prevValue || match;
          }, false);
        }

        let match = childMatch || checkLiMatch(subItem);
        //console.log(subItem, match);
        subItem.hidden = !match;

        return prevValue || match;
      }, false);

      li.hidden = !(childMatch || checkLiMatch(li));

    }

  }
}

module.exports = TutorialMap;

delegate.delegateMixin(TutorialMap.prototype);


function isSubSequence(str1, str2) {
  let i = 0;
  let j = 0;
  while (i < str1.length && j < str2.length) {
    if (str1[i] === str2[j]) {
      i++;
      j++;
    } else {
      i++;
    }
  }
  return j === str2.length;
}

