
let TutorialMapModal = require('./tutorialMapModal');
let TutorialMap = require('./tutorialMap');
let delegate = require('client/delegate');

function init() {

  delegate(document, '[data-action="tutorial-map"]', 'click', (event) => {
    if (event.which !== 1) return; // only left-click, FF needs this
    event.preventDefault();
    showTutorialMapModal();
  });


  let tutorialMapElem = document.querySelector('.tutorial-map');
  if (tutorialMapElem) {
    new TutorialMap(tutorialMapElem);
  } else if (/[&?]map\b/.test(location.href)) {
    showTutorialMapModal();
  }
}


function showTutorialMapModal() {

  if (!/[&?]map\b/.test(location.href)) {
    window.history.replaceState(null, null, ~location.href.indexOf('?') ? (location.href + '&map') : (location.href + '?map'));
  }
  let modal = new TutorialMapModal();
  modal.elem.addEventListener('tutorial-map-remove', () => {
    window.history.replaceState(null, null, location.href.replace(/[&?]map\b/, ''));
  });

}

init();