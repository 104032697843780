module.exports = {
  lang: LANG,
  localCurrency: localCurrency,
  shopCurrency: shopCurrency,
  env: NODE_ENV,
  rateShopTo: rateShopTo,
  countryCode: countryCode,
  ordersMail: ordersMail,
  providers: providers,
  stripeKey: stripeKey,
  paypalClientId: paypalClientId,
  telegramBotId: telegramBotId,
  lookatCodeUrlBase: lookatCodeUrlBase
};
