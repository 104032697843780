module.exports = trackSticky;

// data-sticky= ... bottomLimit: '#signup-sticky-limit'
function trackSticky() {

  let stickyElems = document.querySelectorAll('[data-sticky]');

  for (let i = 0; i < stickyElems.length; i++) {
    let stickyElem = stickyElems[i];

    let options = stickyElem.dataset.sticky ? JSON.parse(stickyElem.dataset.sticky) : {};

    let bottomLimitElem = options.bottomLimit ? document.querySelector(options.bottomLimit) : null;

    let container = options.container ? document.querySelector(options.container) : document.body;

    let widthAllowsToFloat = options.minWidth ? (document.documentElement.clientWidth > options.minWidth) : true;

    if (stickyElem.placeholder) {
      if (stickyElem.placeholder.getBoundingClientRect().top > 0 || !widthAllowsToFloat) {
        // become non-fixed
        stickyElem.style.cssText = '';
        stickyElem.classList.remove('sticky');
        stickyElem.placeholder.parentNode.insertBefore(stickyElem, stickyElem.placeholder);
        stickyElem.placeholder.remove();

        stickyElem.placeholder = null;
      }
    } else if (stickyElem.placeholder && bottomLimitElem) {
      if (bottomLimitElem.getBoundingClientRect().top <= stickyElem.offsetHeight) {
        if (stickyElem.style.position == 'fixed') {
          stickyElem.style.top = window.pageYOffset + 'px';
        }
        stickyElem.style.position = 'absolute';
      } else {
        stickyElem.style.position = 'fixed';
        stickyElem.style.top = 0;
      }
    } else if (stickyElem.getBoundingClientRect().top < 0 && widthAllowsToFloat) {
      // become fixed
      if (stickyElem.style.cssText) {
        // already fixed
        // inertia: happens when scrolled fast too much to bottom
        // http://ilyakantor.ru/screen/2015-02-24_1555.swf
        return;
      }

      let savedLeft, savedRight;
      if (options.saveRight) {
        savedRight = document.documentElement.clientWidth - stickyElem.getBoundingClientRect().right;
      } else {
        savedLeft = stickyElem.getBoundingClientRect().left;
      }

      let placeholder = options.noPlaceholder ? document.createElement('div') : createPlaceholder(stickyElem);

      let width = stickyElem.offsetWidth;

      stickyElem.after(placeholder);

      container.appendChild(stickyElem);
      stickyElem.classList.add('sticky');
      stickyElem.style.position = 'fixed';
      stickyElem.style.top = 0;

      if (options.saveRight) {
        stickyElem.style.right = savedRight + 'px';
      } else {
        stickyElem.style.left = savedLeft + 'px';
      }

      // zIndex < 1000, because it must be under an overlay,
      // e.g. sitemap must show over the progress bar
      stickyElem.style.zIndex = 101;
      stickyElem.style.background = 'white'; // non-transparent to cover the text
      stickyElem.style.margin = 0;
      stickyElem.style.width = width + 'px'; // keep same width as before
      stickyElem.placeholder = placeholder;
    }
  }

}

/**
 * Creates a placeholder w/ same size & margin
 * @param elem
 * @returns {*|!HTMLElement}
 */
function createPlaceholder(elem) {
  let placeholder = document.createElement('div');
  let style = getComputedStyle(elem);
  placeholder.style.width = elem.offsetWidth + 'px';
  placeholder.style.marginLeft = style.marginLeft;
  placeholder.style.marginRight = style.marginRight;
  placeholder.style.position = style.position;
  placeholder.style.height = elem.offsetHeight + 'px';
  placeholder.style.marginBottom = style.marginBottom;
  placeholder.style.marginTop = style.marginTop;
  return placeholder;
}
